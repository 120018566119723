import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import VueDaumPostcode from "vue-daum-postcode"
import VueGoogleCharts from 'vue-google-charts'
import { language } from '@/resources/lang/kr/common'
import { storage } from "./resources/storage/storage"
import RouterTab from 'vue-router-tab'
import 'vue-router-tab/dist/lib/vue-router-tab.css'
import axios from "@/resources/axios/axios";
import data from "@/resources/config/data";
import {date} from "@/resources/date/date";
import {codes} from "@/resources/config/codes";
import {rules} from "@/resources/rules/rules";
import { Notify } from '@/components/AlertMsg'
import { filter } from '@/resources/filter/filter'

Vue.config.productionTip = false

Vue.use(VueDaumPostcode)
Vue.use(VueGoogleCharts)
Vue.use(RouterTab)
Vue.prototype.$language = language
Vue.prototype.$storage = storage
Vue.prototype.date = date
Vue.prototype.codes = codes
Vue.prototype.rules = rules
Vue.prototype.Notify = Notify
Vue.prototype.filter = filter
Vue.use(axios)
data(Vue.prototype)




new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
