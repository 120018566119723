<template>
	<div
		class="wrap-loading"
	>
		<div
			class="bg-loading"
		></div>
		<div
			class="box-loading"
		>
			<v-icon class="spin">mdi-loading</v-icon>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Loading'
	,props: ['is_loading']
	,data: function(){
		return {

		}
	}
	,mounted() {

	}
}
</script>

<style>

	.wrap-loading { position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 99999}
	.bg-loading { position: absolute; width: 100%; height: 100%; background-color: black; opacity: 0.5}
	.box-loading { position: relative; height: 100%; background: none; display: flex; flex-direction: column; justify-content: center; z-index: 99999}

@keyframes spinner {
	to {transform: rotate(-360deg);}
}

.spin {
	animation: spinner 2s linear infinite;
	color: #EC758B !important; font-size: 84px !important;
}
</style>