import Vue from 'vue'
import VueRouter from 'vue-router'
import {RouterTabRoutes} from 'vue-router-tab'

Vue.use(VueRouter)
const routes = [
      ...RouterTabRoutes,
      {
        path: '/intro'
        , name: 'intro'
        , component: () => import('@/view/Main/Intro.vue')
      }
      , {
        path: '/'
        , name: 'Index'
        , component: () => import('@/view/Main/Index.vue')
      }
      , {
        path: '/Login'
        , name: 'Login'
        , component: () => import('@/view/Auth/Login.vue'),
        meta: {
          title: '로그인'
        }
      }
      , {
        path: '/Quick/List'
        , name: 'QuickList'
        , component: () => import('@/view/Quick/QuickList.vue'),
        meta: {
          title: '배달비 결제'
        }
      }
      , {
        path: '/Quick/Settlement'
        , name: 'QuickSettlement'
        , component: () => import('@/view/Quick/QuickSettlement.vue'),
        meta: {
          title: 'Login'
        }
      }
      , {
        path: '/Quick/Settlement/List'
        , name: 'QuickSettlementList'
        , component: () => import('@/view/Quick/QuickSettlementList.vue'),
        meta: {
          title: 'Login'
        }
      }
      , {
        path: '/Quick/Transfer/List'
        , name: 'QuickTransferList'
        , component: () => import('@/view/Quick/QuickTransferList.vue'),
        meta: {
          title: '배달비 지급이체 내역'
        }
      }
      , {
        path: '/Quick/scheduled/settlement'
        , name: 'QuickScheduledSettlement'
        , component: () => import('@/view/Quick/QuickScheduledSettlement.vue'),
        meta: {
          title: 'Login'
        }
      }
      , {
        path: '/Monthly/Payment/List'
        , name: 'MonthlyPaymentList'
        , component: () => import('@/view/Monthly/MonthlyPaymentList.vue'),
        meta: {
          title: '월세 납입'
        }
      }
      , {
        path: '/Monthly/Review/List'
        , name: 'MonthlyReviewList'
        , component: () => import('@/view/Monthly/MonthlyReviewList.vue'),
        meta: {
          title: '월세 심사'
        }
      }
      , {
        path: '/Monthly/Schedule'
        , name: 'MonthlySchedule'
        , component: () => import('@/view/Monthly/MonthlySchedule.vue'),
        meta: {
          title: '월세 내역'
        }
      }
      , {
        path: '/Agency/List'
        , name: 'AgencyList'
        , component: () => import('@/view/Agency/AgencyList.vue'),
        meta: {
          title: '영업점 관리'
        }
      }
      , {
        path: '/Agency/Item'
        , name: 'AgencyItem'
        , component: () => import('@/view/Agency/AgencyItem.vue'),
        meta: {
          title: '영업점 등록'
        }
      }
      , {
        path: '/Agency/Detail/:idx'
        , name: 'AgencyDetail'
        , component: () => import('@/view/Agency/AgencyDetail.vue'),
        meta: {
          title: route => `영업점 상세 ${route.params.idx}`,
          key: 'path'
        }
      }
      , {
        path: '/Merchant/List'
        , name: 'MerchantList'
        , component: () => import('@/view/Merchant/MerchantList.vue'),
        meta: {
          title: '가맹점 관리'
        }
      }
      , {
        path: '/Merchant/Item'
        , name: 'MerchantItem'
        , component: () => import('@/view/Merchant/MerchantItem.vue'),
        meta: {
          title: '가맹점 등록'
        }
      }
      , {
        path: '/Merchant/Detail/:idx'
        , name: 'MerchantDetail'
        , component: () => import('@/view/Merchant/MerchantDetail.vue'),
        meta: {
          title:  route => `가맹점 상세 ${route.params.idx}`,
          key: 'path'
        }
      }
      , {
        path: '/Merchant/Account'
        , name: 'ShopQuickAccountList'
        , component: () => import('@/view/Merchant/AccountList.vue'),
        meta: {
          title: '배대사 가상계좌 관리'
        }
      }
      , {
        path: '/Payment/List'
        , name: 'PaymentList'
        , component: () => import('@/view/Payment/PaymentList.vue'),
        meta: {
          title: '결제 내역'
        }
      }
      , {
        path: '/Board/Notice/Branch'
        , name: 'NoticeBranch'
        , component: () => import('@/view/Board/NoticeBranch.vue'),
        meta: {
          title: '지사 공지사항'
        }
      }
      , {
        path: '/Board/Notice/List'
        , name: 'NoticeList'
        , component: () => import('@/view/Board/NoticeList.vue'),
        meta: {
          title: 'Payster 공지사항'
        }
      }
      , {
        path: '/Board/Notice/Item'
        , name: 'NoticeItem'
        , component: () => import('@/view/Board/NoticeItem.vue'),
        meta: {
          title: '공지 등록'
        }
      }
      , {
        path: '/Board/Notice/Detail/:idx'
        , name: 'NoticeDetail'
        , component: () => import('@/view/Board/NoticeDetail.vue'),
        meta: {
          title: '공지 상세',
          // key: 'path'
        }
      }
      , {
        path: '/Board/Notice/View/:idx'
        , name: 'NoticeView'
        , component: () => import('@/view/Board/NoticeView.vue'),
        meta: {
          title: '지사 공지 상세'
        }
      }
      , {
        path: '/Board/Faq/Category'
        , name: 'FaqCategory'
        , component: () => import('@/view/Board/FaqCategory.vue'),
        meta: {
          title: 'Login'
        }
      }
      , {
        path: '/Board/Faq/List'
        , name: 'FaqList'
        , component: () => import('@/view/Board/FaqList.vue'),
        meta: {
          title: 'FAQ'
        }
      }
      , {
        path: '/Board/Faq/Item'
        , name: 'FaqItem'
        , component: () => import('@/view/Board/FaqItem.vue'),
        meta: {
          title: 'FAQ 등록'
        }
      }
      , {
        path: '/Board/Faq/Detail/:idx'
        , name: 'FaqDetail'
        , component: () => import('@/view/Board/FaqDetail.vue'),
        meta: {
          title: 'FAQ 상세',
          // key: 'path'
        }
      }
      , {
        path: '/Board/Qna/List'
        , name: 'QnaList'
        , component: () => import('@/view/Board/QnaList.vue'),
        meta: {
          title: '1:1 문의'
        }
      }
      , {
        path: '/Board/Qna/Detail/:idx'
        , name: 'QnaDetail'
        , component: () => import('@/view/Board/QnaDetail.vue'),
        meta: {
          title: '1:1 문의 상세',
          // key: 'path'
        }
      }
      , {
        path: '/Settlement'
        , name: 'Settlement'
        , component: () => import('@/view/Settlement/Settlement.vue'),
        meta: {
          title: 'Login'
        }
      }
      , {
        path: '/Settlement/Merchant/List'
        , name: 'SettlementMerchantList'
        , component: () => import('@/view/Settlement/SettlementMerchantList.vue'),
        meta: {
          title: '가맹점 정산 내역'
        }
      }
      , {
        path: '/Settlement/Merchant/Minus/List'
        , name: 'SettlementMerchantMinusList'
        , component: () => import('@/view/Minus/MinusMerchantList'),
        meta: {
          title: '가맹점 차감'
        }
      }
      , {
        path: '/Settlement/Agency/List'
        , name: 'SettlementAgencyList'
        , component: () => import('@/view/Settlement/SettlementAgencyList.vue'),
        meta: {
          title: '영업점 정산 내역'
        }
      }
      , {
        path: '/Settlement/Mypage'
        , name: 'SettlementMypage'
        , component: () => import('@/view/Settlement/SettlementMypage.vue'),
        meta: {
          title: '마이 정산 내역'
        }
      }
      , {
        path: '/Settlement/Agency'
        , name: 'SettlementAgency'
        , component: () => import('@/view/Settlement/SettlementAgency.vue'),
        meta: {
          title: 'Login'
        }
      }
      , {
        path: '/Terms/List'
        , name: 'TermsList'
        , component: () => import('@/view/Terms/TermsList.vue'),
        meta: {
          title: '약관'
        }
      }
      , {
        path: '/Terms/History'
        , name: 'TermsHistory'
        , component: () => import('@/view/Terms/TermsHistory.vue'),
        meta: {
          title: 'Login'
        }
      }
      , {
        path: '/Terms/Item'
        , name: 'TermsItem'
        , component: () => import('@/view/Terms/TermsItem.vue'),
        meta: {
          title: 'Login'
        }
      }
      , {
        path: '/Terms/Detail/:idx'
        , name: 'TermsDetail'
        , component: () => import('@/view/Terms/TermsDetail.vue'),
        meta: {
          title: 'Login'
        }
      }
      , {
        path: '/Management/Mypage'
        , name: 'Mypage'
        , component: () => import('@/view/Management/Mypage.vue'),
        meta: {
          title: '마이페이지'
        }
      }
      , {
        path: '/Management/Holiday'
        , name: 'Holiday'
        , component: () => import('@/view/Management/Holiday.vue'),
        meta: {
          title: '공휴일'
        }
      }
      , {
        path: '*',
        name: '404',
        component: () => import('@/view/Error/NotFound.vue'),
        meta: {
          title: 'Login'
        }
      }
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	next();
});

export default router
