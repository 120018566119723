<template>
    <div
        class="full-height flex-column bg-base theme-dark"
    >
        <Loading
            v-if="is_loading"
        ></Loading>
        <Top
            v-if="program.top"
            :user="user"
            @push="push"
        ></Top>

        <div class="full-height justify-space-between overflow-y-auto">
            <Side
                v-if="is_side && !program.not_side"
                :menu="menu"

                @push="push"
                style="min-width: 15.5rem;"
            ></Side>
            <div class="full-width bg-white">
                <router-view v-if="this.$route.name === 'Login'"></router-view>
                <router-tab v-else ref="RouterTab" style="height: 100%;" :tabs="tabs"
                            :page-transition="{ name: 'none'}"
                            :tab-transition="{ name: 'none'}"
                />
            </div>
        </div>

        <Notify
            :msg="notifyCondition.message"
            :type="notifyCondition.type"

            @clearMsg="clearMsg"
        />
    </div>
</template>

<script>

import Notify from '@/components/AlertMsg'
import Loading from "@/view/Layout/Loading";
import Side from "@/view/Layout/Side";
import Top from "@/view/Layout/Top.vue";

export default {
    name: 'Layout'
    , props: ['TOKEN', 'menu', 'user']
    , components: {Top,Side, Loading, Notify}
    , data: function () {
        return {
            program: {}
            , notifyCondition: {
                message: '메시지 입니다'
                , type: ''
            }
            , is_loading: false
            , is_side: false
            , query: {}
            , tabs: [
                // {
                //     to: 'login',
                //     title: '로그인',
                //     closable: true
                // }
            ]
        }
    }
    , methods: {
        setNotify: function ({type, message}) {
            this.notifyCondition = {
                message: message
                , type: type
            }
        }
        , onLoading: function () {
            this.is_loading = true
        }
        , offLoading: function () {
            setTimeout(() => {
                this.is_loading = false
            }, 500)
        }
        , clearMsg: function () {
            this.notifyCondition.message = ''
        }
        , push: function ({name, params, query, not_query}) {

            this.$router.push({name: name, params: params, query: query}).catch(function (e) {
                console.log(e)
            })
            if (!not_query) {
                localStorage.removeItem('query')
                this.query = {}
            } else {
                this.setQuery()
            }
        }
        , onLoad: function (program) {
            this.program = program
            if(this.$tabs.activeTabId) {
                setTimeout(() => {
                    if(this.$route.name !== 'Login' && this.$tabs.items[10]){
                        this.$tabs.close({id: this.$tabs.items[0].id})
                    }
                }, 100)
            }
            console.log(this.program ,' program')
        }
        , setQuery: function () {
            let store = localStorage.getItem('query')
            if (store) {
                this.query = JSON.parse(store)
            }
        }
        , refreshTab: function (item) {
            this.$tabs.refresh(item)
        }
    }
    , created() {
        this.setQuery()
        console.log(this.$tabs, 'tabs')
    }
    , watch: {
        '$tabs.activeTabId': {
            immediate: true
            ,handler: function (call) {
                if(call) {
                    setTimeout(() => {
                        if(this.$tabs.items[10]){
                            this.$tabs.close({id: this.$tabs.items[0].id})
                        }
                    }, 1000)
                }
            }
        },
        TOKEN: {
            immediate: true
            , handler: function (call) {
                console.log(this.TOKEN, 'token set 000 ------ 1')
                this.is_side = !!call;
            }
        }
    }
}

</script>

<style lang="css" src="@/assets/css/reset.css"></style>
<style lang="css" src="@/assets/css/base.css"></style>


<style>
.v-date-picker-table .v-btn.v-btn--active {
    color: #c74242 !important;
}

.v-picker__body {
    border: 1px solid #bbb
}


.toastui-editor-defaultUI-toolbar button {
    margin: 7px 0px;
}


</style>