

export const language = {
	common: {
		askRemove: '삭제하시겠습니까?'
		,not_select: '선택된 항목이 없습니다.'
		,success: '정상적으로 처리되었습니다'
		,fail: 'DB 오류!'
		,error: '시스템 오류!'
	}
}