<template>
	<div
		class="top justify-space-between"
	>
		<div class="logo cursor-pointer" @click="toIndex"><img src="@/assets/images/logo.png" /></div>
		<div class="pa-10 flex-1 justify-space-between">
			<span class="justify-center">{{ user.roleName }}</span>

			<div
				class="justify-center mr-10"
			>

				<span class="mr-10">{{ user.name }} <span v-if="user.code">({{ user.code }})</span> 님</span>

				<span class="mr-30 box radius-10 prl-10 cursor-pointer" @click="toMypage">내정보</span>

				<button
					@click="isLogout"
				>로그아웃</button>
			</div>
		</div>
		<Modal
			:is_modal="is_modal"
			:option="{}"
			:top="true"
			:bottom="true"

			title="로그아웃"
			content="로그아웃하시겠습니까?"
			width="360px"
			content_class="ptb-30"
			class="color-333"

			@close="close"
			@click="logout"
			@cancel="close"
		></Modal>
		<Modal
			:is_modal="is_modal2"
			:option="{}"
			:top="true"
			:bottom="true"

			title="내 정보"
			class="color-333"

			@close="close"
			@click="close"
			@cancel="close"
		>

		</Modal>
	</div>
</template>

<script>
	import Modal from "@/components/Modal";
	export default {
		name: 'Top'
		,
		components: {Modal},
		props: ['user']
		,data: function(){
			return {
				is_modal: false
				, is_modal2: false
			}
		}
		,computed: {

		}
		,methods: {
			logout: function(){
				sessionStorage.removeItem(process.env.VUE_APP_NAME + 'AT')
				document.location.href= '/'
			}
			,isLogout: function(){
				this.is_modal = true
			}
			,close: function(){
				this.is_modal = false
				this.is_modal2 = false
			}
			,toIndex: function(){
				this.$layout.push( {name: 'Index'})
			}
			,toMypage: function(){
				this.$layout.push( {name: 'Mypage'})
			}
		}
        ,created() {
            this.$layout = this.$root.$children[0].$children[0]
        }
	}
</script>


<style>
.top {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: #2E3539;
	height: 60px;
	color: #eee;
}

.top .logo {
	display: flex; flex-direction: column; justify-content: center; text-align: center;
	flex-basis: 220px;
	height: 60px;
	align-items: center;
}
.top .logo img { width: 122px}

.modal-container {
	width: 50% !important;
	margin: 0 auto;
}

.shop-table td { text-align: left}


.toastui-editor-defaultUI-toolbar button {
	margin: 7px 0px;
}
</style>