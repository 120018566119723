

export const metaInfo = {
    title: '페이스터'
    ,meta: [
        {
            name: 'viewport'
            ,content: 'width=device-width,initial-scale=1.0,user-scalable=no'
        }
        , {
            charset: 'utf-8'
        }
    ]
}